const style = `
    :host(.survey-redirect) {
        position: absolute;
        left: 0; top: 0;
        width: 100%; height: 0;
       font-size: 1rem;

    }
    #redirect {
       background: black;
       width: 100%;
       height: 100%;
       color: #fff;
       font-weight: 500;
       letter-spacing: 0.03rem;
       text-align: center;
       position: absolute;
       top: 0; left: 0;
       display: none;
       opacity: 0;
       transition: opacity 0.5s ease-in-out;
       justify-content: center;
       align-items: center;
    }
    #redirect p {
        text-align: left;
        width: 245px;
        padding: 0 0.5rem;
    }

   
  }
`;

const sheet = new CSSStyleSheet();

sheet.replace(style);

customElements.define(
  "survey-redirect",
  class extends HTMLElement {
    constructor() {
      super();

      let surveyRedirectTemplate = document.createElement("template");

      surveyRedirectTemplate.innerHTML = `
                <div id="redirect">
                    <p>Redirecting to Survey Partner</p>
                </div>
            `;

      let surveyRedirectContent = surveyRedirectTemplate.content;

      const shadowRoot = this.attachShadow({ mode: "open" });
      shadowRoot.appendChild(surveyRedirectContent.cloneNode(true));
      shadowRoot.adoptedStyleSheets = [sheet];

      let style = shadowRoot.getRootNode().host.getAttribute("data-style")
        ? shadowRoot.getRootNode().host.getAttribute("data-style")
        : "";
      this.redirectHTML = shadowRoot.getElementById("redirect");
      this.redirectHTML.style = style;
    }
    redirect(href, duration) {
      const origHTML = this.redirectHTML.querySelector("p").innerHTML;
      const animDuration = 500;
      let counter = 0;
      let dotInterval = setInterval(() => {
        counter++;
        if (counter % 4 === 0) {
          this.redirectHTML.querySelector("p").innerHTML = origHTML;
          counter = 0;
        } else {
          this.redirectHTML.querySelector("p").innerHTML += ".";
        }
      }, animDuration);

      setTimeout(() => {
        window.location.href = href;
      }, duration);
    }
  }
);
